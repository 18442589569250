import { IBaseItem, ParseBaseItem } from "../helpers/helpers";

export interface ICompanyLiaison extends IBaseItem {
	id?: number;
	name: string;
	email: string;
	role?: string;
	contactNumber: string;
	notes?: string;

}

export class CompanyLiaison {
	static Parse(item: ICompanyLiaison) {
		return ParseBaseItem(item);
	}
}