export interface IPermissions {
	// GA Access (GlobalAdmin)
	isGlobalAdmin: boolean;
	// Check 5 Admin
	isCheck5Admin: boolean;
	// Check 5 staff permission for managing the default GNP items (Check5Admin)
	canManageGlobalNetworkPartners: boolean;
	// Read company and company admin users only
	canReadCompany: boolean;
	// Company admin permission for managing data (Auth0 users, network partners etc.) (CompanyAdmin)
	canManageCompany: boolean;
	// Access to view company survey data
	canReadSurveys: boolean;
	// Ability to manage company surveys, send sms, add notes etc. (Wellbeing Team)
	canEditSurveys: boolean;
	// View company employees and teams
	canReadEmployeeData: boolean;
	// Manage company employees (within cap) and teams (User Admin)
	canEditEmployeeData: boolean;
	// View company report data as dashboard page (Reporting User)
	showReportAsDashboard: boolean;
}

export const PermissionsDefault: IPermissions = {
	isGlobalAdmin: false,
	isCheck5Admin: false,
	canManageGlobalNetworkPartners: false,
	canReadCompany: false,
	canManageCompany: false,
	canReadSurveys: false,
	canEditSurveys: false,
	canReadEmployeeData: false,
	canEditEmployeeData: false,
	showReportAsDashboard: false,
};