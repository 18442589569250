import { IFetch } from "./fetch.hooks";
import {ICompanyLiaisonRequests} from "../interfaces/apiRequests";
import {CompanyLiaison, ICompanyLiaison} from "../models/companyLiaison.model";

export function useCompanyLiaisonRequests(http: IFetch): ICompanyLiaisonRequests {
	async function Create(item: ICompanyLiaison): Promise<ICompanyLiaison> {
		const json = await http.Post<ICompanyLiaison>(`companyLiaison`, item);
		return CompanyLiaison.Parse(json);
	}

	async function Get(): Promise<ICompanyLiaison[]> {
		const json = await http.Get<ICompanyLiaison[]>(`companyLiaison`);
		return json.map(i => CompanyLiaison.Parse(i));
	}

	async function GetById(id: number): Promise<ICompanyLiaison> {
		const json = await http.Get<ICompanyLiaison>(`companyLiaison/${id}`);
		return CompanyLiaison.Parse(json);
	}

	async function Update(item: ICompanyLiaison): Promise<ICompanyLiaison> {
		const json = await http.Put<ICompanyLiaison>(`companyLiaison`, item);
		return CompanyLiaison.Parse(json);
	}

	async function Delete(id: number): Promise<ICompanyLiaison> {
		const json = await http.Delete<ICompanyLiaison>(`companyLiaison?id=${id}`);
		return CompanyLiaison.Parse(json);
	}

	return {
		Create,
		Get,
		GetById,
		Update,
		Delete,
	};
}