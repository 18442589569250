import { IFetch } from "./fetch.hooks";
import {ICheck5AdminRequests} from "../interfaces/apiRequests";
import {CompanyDashboard, ICompanyDashboard} from "../models/companyDashboard.model";
import {ICompany} from "../models/company.model";

export function useCheck5AdminRequests(http: IFetch): ICheck5AdminRequests {

	async function GetCheck5Companies(): Promise<ICompanyDashboard[]> {
		const json = await http.Get<ICompanyDashboard[]>(`check5admin/companies`);
		return json.map(i => CompanyDashboard.Parse(i));
	}
	async function SwitchCompany(companyId: number): Promise<ICompany> {
		return await http.Post<ICompany>(`check5admin/switch?companyId=${companyId}`);
	}

	return {
		GetCheck5Companies,
		SwitchCompany
	};
}