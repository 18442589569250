import {IBaseItem, ParseBaseItem} from "../helpers/helpers";

export interface ICompanyDashboard extends IBaseItem {
    id:                         number;
    tenantId:                   number;
    name:                       string;
    employeeCount:              number;
    outstandingSupportRequests: number;
    unassignedSupportRequests:  number;
    surveysByAssignee:          ISurveysByAssignee[];
    scheduleLastRunDate?:       Date;
    scheduleNextRunDate?:       Date;
    employeeCap?:               number;
}

export class CompanyDashboard {
    static Parse(item: ICompanyDashboard) {
        item = ParseBaseItem(item);
        item.scheduleLastRunDate = item.scheduleLastRunDate ? new Date(item.scheduleLastRunDate) : undefined;
        item.scheduleNextRunDate = item.scheduleNextRunDate ? new Date(item.scheduleNextRunDate) : undefined;
        return item;
    }
}

export interface ISurveysByAssignee {
    assignedUser:        string;
    supportRequestCount: number;
}